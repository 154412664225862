import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import { initAuth } from "@/feature-auth/utils/auth-actions.mjs";
import getBearerToken from "@/feature-auth/utils/get-auth-request-header.mjs";
import * as API from "@/feature-wallet/api.mjs";
import { PaymentPlansModel } from "@/feature-wallet/models/payment-plans.mjs";
import { UserPaymentMethodsModel } from "@/feature-wallet/models/user-payment-methods.mjs";
import { UserSubscriptionsModel } from "@/feature-wallet/models/user-subscriptions.mjs";
import { UserTransactionsModel } from "@/feature-wallet/models/user-transactions.mjs";
import { walletRefs } from "@/feature-wallet/refs.mjs";
import { devError } from "@/util/dev.mjs";

export default function fetchData([currentTab]: string[]) {
  async function sideEffect() {
    await initAuth();

    const user = readState.user;
    const bearerToken = await getBearerToken();
    const isLoggedIn = Boolean(user && bearerToken);
    if (!isLoggedIn) return;

    const requestOptions = { headers: { Authorization: bearerToken } };

    return Promise.all([
      getData(
        API.getUserPaymentMethods(),
        UserPaymentMethodsModel,
        ["wallet", "paymentProviders"],
        requestOptions,
      ),
      getData(
        API.getUserSubscriptions(),
        UserSubscriptionsModel,
        ["wallet", "subscriptions"],
        requestOptions,
      ),
      getData(
        API.getPaymentPlans(),
        PaymentPlansModel,
        ["wallet", "paymentPlans"],
        requestOptions,
      ),
      currentTab === "transactions" &&
        getData(
          API.getUserTransactions(),
          UserTransactionsModel,
          ["wallet", "transactions"],
          requestOptions,
        ),
    ]);
  }

  sideEffect().catch((error) => {
    devError("ERROR LOADING WALLET USER INFO", error);
  });

  return Promise.all(walletRefs.fetchFunctions.map((fn) => fn()));
}
